import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";

import LoginView from "./LoginView";
import { loginUser, resetError, signOut, loginTotp, finishSetup } from "./LoginState";

export default compose(
	connect(
		(state) => ({
			isLoading: state.login.isLoading,
			isAuthenticated: state.login.isAuthenticated,
			totpOpen: state.login.totpOpen,
			error: state.login.error,
			totp: state.login.totp,
		}),
		{ loginUser, resetError, signOut, loginTotp, finishSetup }
	),
	withState("activeTabId", "setActiveTabId", 0),
	withState("forgotPasswordOpen", "setForgotPassword", false),
	withState("isChrome", "setIsChrome", true),
	withState("nameValue", "setNameValue", ""),
	withState("totpCode", "setTotpCode", ["", "", "", "", "", ""]),
	withState("loginValue", "setLoginValue", ""),
	withState("passwordValue", "setPasswordValue", ""),
	withHandlers({
		handleTabChange: (props) => (e, id) => {
			props.setActiveTabId(id);
		},
		closeForgotPassword: (props) => () => {
			props.setForgotPassword(false);
		},
		openForgotPassword: (props) => () => {
			props.setForgotPassword(true);
		},
		handleInput: (props) => (e, input = "login") => {
			if (props.error) {
				props.resetError();
			}

			if (input === "login") {
				props.setLoginValue(e.target.value);
			} else if (input === "password") {
				props.setPasswordValue(e.target.value);
			} else if (input === "name") {
				props.setNameValue(e.target.value);
			}
		},

		handleTotpCodeComplete: (props) => (code) => {
			props.loginTotp(code);
		},
		handleLoginButtonClick: (props) => () => {
			props.loginUser(props.loginValue, props.passwordValue);
		},
	}),
	lifecycle({
		componentDidMount() {
			let isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad/i).test(navigator.userAgent);

			if (!isMobile) {
				const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

				if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
					isMobile = true;
				}
			}

			//const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

			const isChrome =
				(!!navigator.userAgentData &&
					navigator.userAgentData.brands.some((data) => data.brand == "Chromium")) ||
				(/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor));

			this.props.setIsChrome(isMobile || isChrome);
		},
		componentWillReceiveProps(nextProps) {
			if (!this.props.error && nextProps.error) {
				this.props.setPasswordValue("");
			}
		},
	})
)(LoginView);
