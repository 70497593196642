import axios from "axios";

export const initialState = {
	isLoading: false,
	totpOpen: false,
	isAuthenticated: !!localStorage.getItem("id_token"),
	error: null,
	totp: null,
};

export const START_LOGIN = "Login/START_LOGIN";
export const LOGIN_SUCCESS = "Login/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "Login/LOGIN_FAILURE";
export const RESET_ERROR = "Login/RESET_ERROR";
export const LOGIN_USER = "Login/LOGIN_USER";
export const SIGN_OUT_SUCCESS = "Login/SIGN_OUT_SUCCESS";
export const SETUP_SUCCESS = "Login/SETUP_SUCCESS";

export const startLogin = () => ({
	type: START_LOGIN,
});

export const loginSuccess = (data) => ({
	type: LOGIN_SUCCESS,
	payload: data,
});

export const loginFailure = () => ({
	type: LOGIN_FAILURE,
});

export const resetError = () => ({
	type: RESET_ERROR,
});
export const signOutSuccess = () => ({
	type: SIGN_OUT_SUCCESS,
});
export const setupSuccess = () => ({
	type: SETUP_SUCCESS,
});
export const loginUser = (login, password) => (dispatch) => {
	dispatch(startLogin());

	axios
		.post("/login", { username: login, password: password })
		.then((res) => {
			dispatch(loginSuccess(res.data));
		})
		.catch((err) => {
			dispatch(loginFailure());
		});
	/*if (login == "test" && password == "test2") {
		setTimeout(() => {
			localStorage.setItem("id_token", "1");
			dispatch(loginSuccess());
		}, 2000);
	} else {
		dispatch(loginFailure());
	}*/
};

export const loginTotp = (code) => (dispatch) => {
	axios
		.post("/login-totp", { code: code })
		.then((res) => {
			const urlParams = new URLSearchParams(window.location.search);
			const redirect = urlParams.get("redirect") || "/";

			window.location.href = `${redirect}`;

			//dispatch(loginSuccess(res.data));
		})
		.catch((err) => {
			//dispatch(loginFailure());
		});
};

export const finishSetup = () => (dispatch) => {
	dispatch(setupSuccess());
};

export const signOut = () => (dispatch) => {
	axios
		.post("/logout")
		.then((res) => {
			dispatch(signOutSuccess());
		})
		.catch((err) => {
			console.log("logout error", err);
		});
};

export default function LoginReducer(state = initialState, { type, payload }) {
	switch (type) {
		case START_LOGIN:
			return {
				...state,
				isLoading: true,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				isLoading: false,
				totpOpen: true,
				error: null,
				totp: payload,
			};
		case LOGIN_FAILURE:
			return {
				...state,
				isLoading: false,
				error: true,
			};
		case RESET_ERROR:
			return {
				error: false,
			};
		case SIGN_OUT_SUCCESS:
			return {
				...state,
				isAuthenticated: false,
				totpOpen: false,
			};
		case SETUP_SUCCESS:
			return {
				...state,
				totp: { status: "login", user: state.totp.user },
			};
		default:
			return state;
	}
}
