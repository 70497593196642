import React from "react";
import {
	Grid,
	CircularProgress,
	Typography,
	Button,
	Tabs,
	Tab,
	TextField,
	Fade,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	DialogContentText,
} from "@material-ui/core";
import CodeInput from "./CodeInput";
import { withStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import QRCode from "react-qr-code";

import classnames from "classnames";

import i18n from "i18next";
import { initReactI18next, useTranslation, withTranslation } from "react-i18next";
import backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.use(backend)
	.init({
		/*resources: {
			sv: {
				hej: "hej",
			},
		},*/
		//detection: DETECTION_OPTIONS,
		backend: {
			loadPath: "/locales/{{lng}}.json", // Path to the translation files
			addPath: "/publicapi/locales/{{lng}}",
			allowMultiLoading: false,
		},
		react: {
			useSuspense: false,
			//wait: true,
		},
		saveMissing: true,
		//lng: "sv", // Default language
		fallbackLng: "sv", // Fallback language
	});

const overrides = {
	typography: {
		h1: {
			fontSize: "3rem",
		},
		h2: {
			fontSize: "2rem",
		},
		h3: {
			fontSize: "1.64rem",
		},
		h4: {
			fontSize: "1.5rem",
		},
		h5: {
			fontSize: "1.285rem",
		},
		h6: {
			fontSize: "1.142rem",
		},
	},
};

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#23232C",
		},
	},
	...overrides,
});

const Login = ({ classes, t, ...props }) => (
	<MuiThemeProvider theme={theme}>
		<Grid container className={classes.container}>
			<div className={classes.logotypeContainer}>
				<img src={"/img/logo-main.svg"} alt="logo" className={classes.logotypeImage} />
				{/*<Typography className={classes.logotypeText}>hlthy</Typography>*/}
			</div>
			<div className={classes.formContainer}>
				<div className={classes.form}>
					<Tabs
						value={props.activeTabId}
						onChange={props.handleTabChange}
						indicatorColor="primary"
						textColor="primary"
						centered
					>
						<Tab label={t("Logga in")} classes={{ root: classes.tab }} />
					</Tabs>

					<React.Fragment>
						{false && (
							<React.Fragment>
								<Typography variant="h1" className={classes.greeting}>
									Good Morning
								</Typography>

								<div className={classes.formDividerContainer}>
									<div className={classes.formDivider} />
								</div>
							</React.Fragment>
						)}
						<Fade in={props.error}>
							<Typography color="secondary" className={classes.errorMessage}>
								{t("Ogiltigt användarnamn eller lösenord")}
							</Typography>
						</Fade>
						<TextField
							id="email"
							InputProps={{
								classes: {
									underline: classes.textFieldUnderline,
									input: classes.textField,
								},
							}}
							value={props.loginValue}
							onChange={(e) => props.handleInput(e, "login")}
							margin="normal"
							placeholder={t("Email Adress")}
							type="email"
							fullWidth
						/>
						<TextField
							id="password"
							InputProps={{
								classes: {
									underline: classes.textFieldUnderline,
									input: classes.textField,
								},
							}}
							value={props.passwordValue}
							onKeyDown={(e) => (e.key === "Enter" ? props.handleLoginButtonClick() : false)}
							onChange={(e) => props.handleInput(e, "password")}
							margin="normal"
							placeholder={t("Lösenord")}
							type="password"
							fullWidth
						/>
						<div className={classes.formButtons}>
							{props.isLoading ? (
								<CircularProgress size={26} className={classes.loginLoader} />
							) : (
								<Button
									disabled={
										false && (props.loginValue.length === 0 || props.passwordValue.length === 0)
									}
									onClick={props.handleLoginButtonClick}
									variant="contained"
									color="primary"
									size="large"
								>
									{t("Logga in")}
								</Button>
							)}
							<Button
								color="primary"
								size="large"
								onClick={props.openForgotPassword}
								className={classes.forgetButton}
							>
								{t("Glömt lösenord")}
							</Button>
						</div>
					</React.Fragment>
				</div>
				<Typography color="primary" className={classes.copyright}>
					© The Patient Company AB. All rights reserved.
				</Typography>
			</div>
		</Grid>
		<Dialog
			open={props.forgotPasswordOpen}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent style={{ padding: 24 }}>
				<div>
					<Typography>
						{t(
							"Om du har glömt bort ditt lösenord eller inte kan logga in kontakta den ansvarige på ert företag."
						)}
					</Typography>
				</div>
			</DialogContent>

			<DialogActions>
				<Button variant="contained" color="primary" onClick={props.closeForgotPassword}>
					{t("Ok")}
				</Button>
			</DialogActions>
		</Dialog>
		<Dialog open={!props.isChrome}>
			<DialogContent style={{ padding: 24 }}>
				<DialogContentText>
					{t(
						"Just nu stödjer HLTHY endast Chrome. Vi arbetar på att göra upplevelsen bättre i andra webbläsare, men för att systemet ska fungera ordentligt hänvisar vi er tills dess att hämta Chrome."
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="primary"
					onClick={() => (window.location = "https://www.google.com/intl/sv/chrome/")}
				>
					{t("Hämta Chrome")}
				</Button>
			</DialogActions>
		</Dialog>
		<Dialog
			open={props.totpOpen && props.totp}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			{props.totp && (
				<React.Fragment>
					<DialogContent style={{ padding: 24 }}>
						{props.totp.status === "setup" && (
							<div>
								<Typography gutterBottom>
									{t(
										'1. Ladda ned appen "Microsoft Authenticator" eller "Authy" från Play Store på Android eller App Store på iOS och följ instruktionerna i appen.'
									)}
								</Typography>
								<Typography gutterBottom>{t('2. Klicka "Add Account" och "Scan QR Code".')}</Typography>
								<Typography>
									{t("3. Vänta tills QR koden nedan har laddat och rikta kameran mot QR koden.")}
								</Typography>
								<QRCode style={{ margin: 16 }} size={166} value={props.totp.qrImage} />
								{false && <img style={{ margin: 16 }} src={props.totp.qrImage} />}
								<Typography gutterBottom>
									{t(
										'4. Klicka på "Done" och när du ser en 6-siffrig kod på skärmen klicka på "Klar" nedan.'
									)}
								</Typography>
							</div>
						)}
						{props.totp.status === "login" && (
							<div>
								<Typography style={{ marginBottom: 16, maxWidth: 348, textAlign: "center" }}>
									{t(
										"Skriv in den 6-siffriga koden som visas i Microsoft Authenticator eller Authy-appen"
									)}
								</Typography>
								<CodeInput
									autoFocus={true}
									onComplete={props.handleTotpCodeComplete}
									fields={6}
									type={"number"}
								/>
							</div>
						)}
					</DialogContent>
					{props.totp.status === "setup" && (
						<DialogActions>
							<Button
								onClick={props.handleTotpLogin}
								variant="contained"
								color="primary"
								onClick={props.finishSetup}
							>
								{t("Klar")}
							</Button>
						</DialogActions>
					)}
				</React.Fragment>
			)}
		</Dialog>
	</MuiThemeProvider>
);

const styles = (theme) => ({
	container: {
		height: "100vh",
		width: "100vw",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		top: 0,
		left: 0,
	},
	logotypeContainer: {
		background: `linear-gradient(117.67deg, rgb(153, 48, 255), rgb(255, 119, 211), rgb(255, 109, 27))`,
		width: "60%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			width: "50%",
		},
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	logotypeImage: {
		width: 200,
	},
	logotypeText: {
		color: "white",
		fontFamily: "'Source Sans Pro', sans-serif",
		textShadow: "rgba(0,0,0,0.1) 0px 6px 3px",
		fontWeight: 700,
		fontSize: 54,
		[theme.breakpoints.down("md")]: {
			fontSize: 48,
		},
	},
	formContainer: {
		width: "40%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			width: "50%",
		},
	},
	form: {
		width: 320,
	},
	tab: {
		fontWeight: 400,
		fontSize: 18,
	},
	greeting: {
		fontWeight: 500,
		textAlign: "center",
		marginTop: theme.spacing(4),
	},
	subGreeting: {
		fontWeight: 500,
		textAlign: "center",
		marginTop: theme.spacing(2),
	},

	googleButtonCreating: {
		marginTop: 0,
	},
	googleIcon: {
		width: 30,
		marginRight: theme.spacing(2),
	},
	creatingButtonContainer: {
		marginTop: theme.spacing(2.5),
		height: 46,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	createAccountButton: {
		height: 46,
		textTransform: "none",
	},
	formDividerContainer: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		display: "flex",
		alignItems: "center",
	},
	formDividerWord: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	formDivider: {
		flexGrow: 1,
		height: 1,
		backgroundColor: theme.palette.text.hint + "40",
	},
	errorMessage: {
		textAlign: "center",
	},

	formButtons: {
		width: "100%",
		marginTop: theme.spacing(4),
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	forgetButton: {
		textTransform: "none",
		fontWeight: 400,
	},
	loginLoader: {
		marginLeft: theme.spacing(4),
	},
	copyright: {
		position: "absolute",
		bottom: theme.spacing(2),
	},
});

export default withTranslation("translations")(withStyles(styles, { withTheme: true })(Login));
